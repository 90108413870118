import React from "react"
import { graphql } from "gatsby"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//import { DateTime } from "luxon"

import Layout from "components/Layout"
import Head from "components/Head"
import ItemTip from "components/ItemTip"

//const pkg = require("root/package.json")
const pjt = require("root/project.json")

const TipsPage = ({ data }) => (
  <Layout>
    <Head
      id="tips"
      pageTitle="便利な使い方"
      pageUrl={pjt.site.url + "/tips/"}
    />
    <section className="section is-tips is-space-half">
      <div className="box is-space-half">
        <div className="card is-separate">
          <div className="card-header">
            <div className="inner is-padding-xs">
              <h2 className="text is-strong is-sm">便利な使い方</h2>
            </div>
          </div>
          <ul className="card-list is-border">
            {data.allAirtable.edges.map((edge, i) => (
              <li key={i}>
                <ItemTip
                  to={`/tips/${edge.node.data.slug}/`}
                  src={edge.node.data.images[0].thumbnails.small.url}
                  title={edge.node.data.title}
                  publishedAt={edge.node.data.publishedAt}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  </Layout>
)

export default TipsPage

export const query = graphql`
  query {
    allAirtable(
      filter: { table: { eq: "tips" }, data: { published: { eq: true } } }
      sort: { fields: [data___publishedAt], order: DESC }
    ) {
      edges {
        node {
          data {
            title
            published
            slug
            publishedAt
            images {
              url
              thumbnails {
                small {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
